<template>
	<div>
		<slot />
	</div>
</template>

<style lang="scss" scoped>
.block-item {
	padding: 20px;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	gap: 60px;
	overflow: hidden;
}

.block-item:nth-child(even),
.block-item:nth-child(odd).reverse {
	flex-direction: row;
}

.block-item:nth-child(even).reverse {
	flex-direction: row-reverse;
}

@media (max-width: 960px) {
	.block-item,
	.block-item:nth-child(even),
	.block-item:nth-child(odd).reverse,
	.block-item:nth-child(even).reverse {
		flex-direction: column;
	}
}

@media (max-width: 600px) {
	.block-item {
		gap: 0;
		padding: 0;
		margin-bottom: 60px;
	}
}
</style>
